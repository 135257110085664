@import "abstracts.scss";

.pencil {
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header {
  top: 0;
  padding: 20px 0 21px;
  z-index: 1000;
  svg {
    overflow: visible;
  }
  @include bp-767 {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.headerHidden {
  opacity: 0;
  visibility: hidden;
}
.headerLogo {
  opacity: 0;
  position: relative;
  bottom: -3px;
}