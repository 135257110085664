@tailwind base;
@tailwind components;
@tailwind utilities;
@import "abstracts";

@layer base {
  @font-face {
    font-family: 'Satoshi Reg';
    src: url('../public/fonts/Satoshi/Satoshi-Regular.woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Satoshi Med';
    src: url('../public/fonts/Satoshi/Satoshi-Medium.woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Satoshi Bold';
    src: url('../public/fonts/Satoshi/Satoshi-Bold.woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Satoshi Italic';
    src: url('../public/fonts/Satoshi/Satoshi-Italic.woff2');
    font-display: swap;
  }

  @font-face {
    font-family: 'GT Haptik Bold';
    src: url('../public/fonts/GtHaptik/GT-Haptik-Bold.woff2');
    font-display: swap;
  }
}

html[data-mantine-color-scheme='dark'],
html[data-mantine-color-scheme='light'] {
  height: 0;
}

html, body {
  overscroll-behavior: none;
}

/* Hide horizontal scrollbar in WebKit-based browsers */
.overflow-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.overflow-scroll {
  scrollbar-width: none;
}

.desktop-only {
  @include bp-767 {
    display: none;
  }
}

.mobile-only {
  display: none;
  @include bp-767 {
    display: block;
  }
}

[class*="hover:"] {
  transition: .22s all $ease-in-out-sine;
}

button {
  cursor: pointer;
}

@layer components {
  .uniqueEffectStroke {
    border: radial-gradient(
                    100% 100% at 0% 0%,
                    #9db4f0 0%,
                    rgba(157, 180, 240, 0.37) 0.01%,
                    rgba(132, 144, 255, 0.01) 100%
    );
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(21px);
  }

  .uniqueFade {
    background: linear-gradient(180deg, #c2c8ff 0%, #b5bcff 50%, #8490ff 100%);
  }

  .radialGradient {
    background: radial-gradient(
                    rgba(157, 180, 240, 1) rgba(157, 180, 240, 0.37) rgba(132, 144, 255, 0.01)
    );
  }

  .navGradient {
    background: radial-gradient(
                    100% 100% at 50% 0,
                    #9db4f0 0,
                    rgb(157 180 240 / 83%) 0.01%,
                    hsl(0deg 0% 100% / 62%) 100%
    );
    visibility: hidden;
    opacity: 0;
    backdrop-filter: blur(27px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .bg-gridOverlay {
    background-image: url('/img/GridOverlay.png');
    background-color: #1A133A;
    background-size: auto;
    background-position: center -320px;
    background-repeat: no-repeat;
  }

  .darkCard {
    background: linear-gradient(180deg, #180b36 0%, #0d0129 100%);
  }

  .pillGradient {
    background: radial-gradient(
                    100% 100% at 0% 0%,
                    rgba(157, 180, 240, 1) 0%,
                    rgba(157, 180, 240, 0.37) 0.01%,
                    rgba(132, 144, 255, 0.01) 100%
    );
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(21px);
  }

  .cardStyle {
    background: radial-gradient(
                    100% 100% at 0% 0%,
                    #9db4f0 0%,
                    rgba(157, 180, 240, 0.37) 0.01%,
                    rgba(132, 144, 255, 0.01) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(21px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
  }

  .cardHover {
    -moz-box-shadow: 0 0 20px #fff;
    -webkit-box-shadow: 0 0 20px #fff;
    box-shadow: rgba(174, 195, 255, 0.5) 0px 0px 30px 4px;
  }

  .walletConnectBg {
    background: radial-gradient(
                    100% 100% at 0% 0%,
                    #9db4f0 0%,
                    rgba(157, 180, 240, 0.37) 0.01%,
                    rgba(132, 144, 255, 0.01) 100%
    );
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(21px);
  }
}

* {
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.enter-el {
  opacity: 0;
  display: flex;
  justify-content: center;
}

.enter-el-block {
  opacity: 0;
  display: block;
}

.enter-el-inherit {
  opacity: 0;
}

.awaiting-content {
  min-height: 600px;
  @media (max-width: 767px) {
    min-height: 800px;
  }
}

.font-gt-haptik {
  font-feature-settings: "liga", "ss01";
}

/* html,
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: #080118;
  }
} */
